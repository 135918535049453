<template>
  <div class="create-mailing-template-page">
    <BackTitle class="mb-40" confirm-click @click="goBack">
      Create mailing template
    </BackTitle>
    <MailingTemplateForm
      :value="mailingTemplate"
      :is-submitting="isSubmitting"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import MailingTemplateForm from "@/components/mailing-templates/MailingTemplateForm";
import { NEW_MAILING_TEMPLATE_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";

export default {
  name: "CreateMailingTemplatePage",
  components: { MailingTemplateForm, BackTitle },
  props: {
    duplicate: {
      type: Object,
    },
  },
  data() {
    return {
      mailingTemplate: {
        ...NEW_MAILING_TEMPLATE_MOCK,
      },
      isSubmitting: false,
    };
  },
  created() {
    if (this.duplicate) {
      this.mailingTemplate = {
        ...this.duplicate,
      };
    }
  },
  methods: {
    ...mapActions({
      createMailingTemplate: "mailingTemplates/createMailingTemplate",
    }),
    goBack() {
      this.$router.push({
        name: "MailingTemplates",
      });
    },
    async handleSubmit(data) {
      try {
        this.isSubmitting = true;
        await this.createMailingTemplate(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-mailing-template-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
